<template>
    <div class="intro-view">
        <div class="intro-view__container">
            <div class="intro-view__col">
                <div class="intro-view__instructions">
                    <img class="tenant-logo" src="../assets/images/tenant-logo.svg" alt="" />
                    <h2 class="headline headline--h1">
                        Well, this Is exiting!
                    </h2>
                    <p>
                        Let’s start with some basic information to give you access to the best
                        prices available.
                    </p>

                    <v-form class="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" md="6">
                                <div class="form__group">
                                    <label>
                                        First Name
                                    </label>
                                    <v-text-field
                                        v-model="firstname"
                                        :rules="nameRules"
                                        filled
                                        required
                                        hide-details="auto"
                                    ></v-text-field>
                                </div>
                            </v-col>

                            <v-col cols="12" md="6">
                                <div class="form__group">
                                    <label>
                                        Last Name
                                    </label>
                                    <v-text-field
                                        v-model="lastName"
                                        :rules="nameRules"
                                        filled
                                        required
                                        hide-details="auto"
                                    ></v-text-field>
                                </div>
                            </v-col>

                            <v-col cols="12" md="12">
                                <div class="form__group">
                                    <label>Business Email</label>
                                    <small class="form__help">Please provide a company email</small>
                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        filled
                                        required
                                        hide-details="auto"
                                    ></v-text-field>
                                </div>
                            </v-col>

                            <v-col cols="12" md="12">
                                <div class="form__group">
                                    <label>Are You A?</label>
                                    <v-select
                                        :items="options"
                                        filled
                                        hide-details="auto"
                                    ></v-select>
                                </div>
                            </v-col>

                            <v-col cols="12" md="12">
                                <div class="form__group">
                                    <label>Create A Password</label>
                                    <small>Password must be at least 8 characters.</small>
                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        filled
                                        required
                                        hide-details="auto"
                                        type="password"
                                    ></v-text-field>
                                </div>
                            </v-col>
                        </v-row>

                        <v-btn @click="$router.push('sign-up')" depressed x-large class="secondary"
                            >Sign up
                        </v-btn>

                        <small class="alternate-option mt-16"
                            >Have you an account? <a href="">Sign In</a></small
                        >
                        <small class="alternate-option mt-7">
                            By clicking the “Sign Up” button, you are creating a Tenant Evaluation
                            account, and you agree to Tenant Evaluation’s
                            <a href="">Terms of Use</a> and <a href="">Privacy Policy</a>.
                        </small>
                    </v-form>
                </div>
            </div>
            <div class="intro-view__col">
                <div class="intro-view__media">
                    <h3>The future of digital onboarding starts here</h3>
                    <img src="../assets/images/phone-with-icons.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SignUp',
    data() {
        return {
            options: ['Property Manager', 'Management Company', 'Board Director', 'Other'],
        };
    },
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';

.alternate-option {
    display: block;
}

.intro-view {
    &__col {
        &:last-child {
            padding: 0;
            background: $gray-200;
        }
    }

    &__media {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > h3 {
            font-family: 'Open Sans', sans-serif;
            font-size: 35px;
            line-height: 40px;
            font-weight: 300;
            color: $text-color-alternative;
            max-width: 380px;
            text-align: center;
            margin-bottom: 50px;
        }
    }
}
</style>
